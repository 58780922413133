import request from '@/utils/request'

// 登录
export function login(params) {
    return request({
      url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_login',
      method: 'get',
      params
    })
}

// 注册
export function register(params) {
    return request({
      url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_reg',
      method: 'get',
      params
    })
}

// 修改资料
export function changeUserinfo(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_updateqy',
    method: 'get',
    params
  })
}

// 获取用户信息
export function getUserinfo(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_userinfo',
    method: 'get',
    params
  })
}

// 获取facebook用户信息
export function getFacebookUserinfo(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_facebook_info',
    method: 'get',
    params
  })
}

//修改密码

export function updatePassword(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_updatepwd',
    method: 'get',
    params
  })
}

//添加模版
export function addTemplate(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_tmp_insert',
    method: 'get',
    params
  })
}

//添加模版
export function addAutoTemplate(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_autotmp_insert',
    method: 'get',
    params
  })
}

//添加huodong
export function addHuoDong(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_huodong_insert',
    method: 'get',
    params
  })
}

//添加排队座位
export function addPaiduiSet(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_set_paidui',
    method: 'get',
    params
  })
}

//修改通用设置
export function api_tongyong_edit(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_tongyong_edit',
    method: 'get',
    params
  })
}

//添加代发列表
export function addDaifa(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_daifa_insert',
    method: 'get',
    params
  })
}

//叫号https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=jh_paidui&id=2
export function jh_paidui(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=jh_paidui',
    method: 'get',
    params
  })
}

//添加任务列表
export function addRenwu(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_sendrw_insert',
    method: 'get',
    params
  })
}
//添加资源到列表
export function addRenwu_zy(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_ziyuan_into',
    method: 'get',
    params
  })
}

//通用列表
export function tongyong(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_tongyong',
    method: 'get',
    params
  })
}

//统计
export function tongji(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_tongji',
    method: 'get',
    params
  })
}

//读取数据库结构
export function sjk(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=sjk',
    method: 'get',
    params
  })
}

//读取数据库结构
export function funk(params) {
  return request({
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger',
    method: 'get',
    params
  })
}

export function imageUpload(data) {
  return request( {
    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_img_down',
    method:'POST',
    headers: {'content-type': 'multipart/form-data'},
    data
  });
}

 
